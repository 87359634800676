import React, { useState } from 'react';

import { NoInternetConnection } from 'Src/Components/EmptyStatesNew/EmptyStates';
const NetworkDisabled = ({ children }: any) => {
    // state variable holds the state of the internet connection
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    // event listeners to update the state
    window.addEventListener('online', () => {
        setIsOnline(true);
    });

    window.addEventListener('offline', () => {
        setIsOnline(false);
    });

    // if user is online, return the child component else return a custom component
    if (isOnline) {
        return children;
    } else {
        return (
            <div className="page-container">
                <NoInternetConnection />
            </div>
        );
    }
};

export default NetworkDisabled;
